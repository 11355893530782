<template>
    <main class="swcluster-main" id="swcluster-datasandbox-apply">
        <!-- page-body -->
        <div class="page-body page-component">
            <div class="apply-contents">
                <h2 class="title">이용신청이 마감되었습니다. <br />2023년도 사업에 신청해주세요.</h2>
                <a href="javascript:" class="link" @click="notice">다른 사업공고 보기 <i class="icon-link"></i></a>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import {useRouter} from 'vue-router';
import {domainCode} from '@/assets/js/domain.code';
export default {
    name: 'Apply',
    components: {},
    setup() {
        const router = useRouter();
        const notice = () => {
            router.push({name: 'Notice', query: {boardTypeDcd: domainCode.BOARD_TYPE_BUSINESS}});
        };

        return {
            notice,
        };
    },
};
</script>
